.admin-event {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.admin-event h3 {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 0px;
    text-align: center;
}

/* Mode Switch */
.mode-switch {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
    flex-direction: row; /* Default horizontal for larger screens */
}

.mode-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #f0f0f0;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    border: none;
    border-radius: 25px;
    padding: 10px 20px; /* Default padding for larger screens */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
    white-space: nowrap; /* Prevent text wrapping */
    flex-shrink: 1; /* Allow shrinking if needed */
    min-width: 120px; /* Minimum width for readability */
}

.mode-button.active {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.mode-button:hover:not(.active) {
    background-color: #e0e0e0;
    transform: translateY(-2px);
}

.mode-button:active {
    transform: translateY(0);
}

/* Dress Code Section */
.dress-code-section {
    background: linear-gradient(135deg, #f9f5f8 0%, #fdf8f5 100%);
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(107, 69, 106, 0.2);
    margin-bottom: 30px;
}

.dress-code-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dress-code-form .form-group {
    margin-bottom: 0;
}

.dress-code-form label,
.form-group label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: var(--text);
    margin-bottom: 8px;
    display: block;
}

.dress-code-form input,
.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(107, 69, 106, 0.3);
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text);
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.dress-code-form input:focus,
.form-group input:focus,
.form-group textarea:focus {
    border-color: var(--primary);
    box-shadow: 0 0 6px rgba(107, 69, 106, 0.3);
}

.dress-code-form input::placeholder,
.form-group input::placeholder,
.form-group textarea::placeholder {
    color: var(--primary);
    opacity: 0.7;
}

.dress-code-form .save-button,
.submit-button {
    background-color: var(--primary);
    color: var(--background);
    border: none;
    border-radius: 8px;
    padding: 12px 25px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.dress-code-form .save-button:hover,
.submit-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    transform: translateY(-2px);
}

.dress-code-form .save-button:active,
.submit-button:active {
    transform: translateY(0);
}

.dress-code-display {
    text-align: center;
    margin-top: 20px;
}

.dress-code-display h4 {
    font-family: "Dancing Script", cursive;
    font-size: 22px;
    color: var(--primary);
    margin: 0;
}

.dress-code-display h4 span {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--text);
}

/* Event Form */
.event-form {
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group textarea {
    min-height: 120px;
    resize: vertical;
}

.form-error {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #e74c3c;
    margin-bottom: 15px;
    text-align: center;
}

/* Timeline */
.timeline {
    position: relative;
    padding: 20px 0;
}

.timeline::before {
    content: "";
    position: absolute;
    left: 50px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: var(--primary);
}

.timeline-item {
    position: relative;
    margin-bottom: 30px;
    padding-left: 80px;
}

.timeline-item::before {
    content: "";
    position: absolute;
    left: 45px;
    top: 10px;
    width: 12px;
    height: 12px;
    background-color: var(--accent);
    border-radius: 50%;
    border: 2px solid var(--primary);
}

.timeline-content {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.timeline-time {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary);
    margin-right: 20px;
    min-width: 100px;
}

.timeline-details {
    flex: 1;
}

.timeline-details h4 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: var(--primary);
    margin-bottom: 5px;
}

.timeline-details p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text);
    margin: 0;
}

.timeline-actions {
    display: flex;
    gap: 10px;
}

.edit-button,
.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.edit-button {
    color: var(--primary);
}

.delete-button {
    color: #e74c3c;
}

.edit-button:hover {
    background-color: var(--accent);
    color: var(--background);
}

.delete-button:hover {
    background-color: #e74c3c;
    color: #fff;
}

.no-data {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text);
    text-align: center;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .admin-event h3 {
        font-size: 32px;
    }

    .mode-button {
        padding: 12px 25px;
        font-size: 18px;
    }

    .dress-code-display h4 {
        font-size: 24px;
    }

    .timeline::before {
        left: 70px;
    }

    .timeline-item {
        padding-left: 100px;
    }

    .timeline-item::before {
        left: 65px;
        top: 12px;
        width: 14px;
        height: 14px;
    }

    .timeline-time {
        font-size: 18px;
        min-width: 120px;
    }

    .timeline-details h4 {
        font-size: 20px;
    }

    .timeline-details p {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .mode-switch {
        flex-direction: column; /* Stack vertically on small screens */
        gap: 10px;
        align-items: center; /* Center buttons vertically */
    }

    .mode-button {
        padding: 8px 12px; /* Reduced padding for mobile */
        font-size: 14px;
        min-width: 0; /* Allow buttons to shrink fully */
        width: 100%; /* Full width for consistency */
        max-width: 200px; /* Cap width to avoid stretching too much */
    }

    .timeline::before {
        left: 30px;
    }

    .timeline-item {
        padding-left: 50px;
    }

    .timeline-item::before {
        left: 25px;
        top: 8px;
        width: 10px;
        height: 10px;
    }

    .timeline-time {
        font-size: 14px;
        min-width: 80px;
    }

    .timeline-details h4 {
        font-size: 16px;
    }

    .timeline-details p {
        font-size: 12px;
    }

    .dress-code-section,
    .event-form {
        padding: 15px;
    }
}