.manage-images {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.manage-images h2 {
    margin-bottom: 20px;
    color: var(--primary);
    text-align: center;
}

.images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.image-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: white;
    transition: transform 0.2s ease;
}

.image-card:hover {
    transform: translateY(-5px);
}

.image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background: #f5f5f5;
}

.image-title {
    font-size: 1.2rem;
    margin: 10px;
    color: #1a1a1a;
    text-align: center;
}

.image-description {
    font-size: 0.9rem;
    margin: 0 10px 10px;
    color: #666;
    text-align: center;
}

.delete-button {
    display: block;
    margin: 0 auto 10px;
    padding: 8px 16px;
    background: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background 0.3s ease;
}

.delete-button:hover {
    background: #e60000;
}

/* Loader Styles */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.loader {
    animation: spin 1s linear infinite;
    color: var(--primary);
}

.loader-container p {
    margin-top: 10px;
    color: #666;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.modal h3 {
    margin-bottom: 10px;
    color: #1a1a1a;
}

.modal p {
    margin-bottom: 20px;
    color: #666;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modal-cancel,
.modal-confirm {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
}

.modal-cancel {
    background: #ccc;
    color: #1a1a1a;
}

.modal-confirm {
    background: #ff4d4d;
    color: white;
}

.modal-confirm:hover {
    background: #e60000;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .images-grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 15px;
    }

    .image {
        height: 150px;
    }

    .image-title {
        font-size: 1rem;
    }

    .image-description {
        font-size: 0.8rem;
    }

    .delete-button {
        padding: 6px 12px;
        font-size: 0.8rem;
    }

    .modal {
        width: 80%;
        padding: 15px;
    }

    .modal h3 {
        font-size: 1.2rem;
    }

    .modal p {
        font-size: 0.9rem;
    }

    .modal-cancel,
    .modal-confirm {
        padding: 6px 12px;
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .images-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .image {
        height: 120px;
    }

    .image-title {
        font-size: 0.9rem;
    }

    .image-description {
        font-size: 0.7rem;
    }
}