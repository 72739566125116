:root {
    --primary: #004d40;
    --secondary: #00a36c;
    --background: #f5f5f5;
    --text: #1a1a1a;
    --sidebar-bg: #ffffff;
    --sidebar-hover: #008c5a;
}

.admin-layout {
    display: flex;
    min-height: 100vh;
}

.admin-content {
    flex: 1;
    padding: 20px;
    background: var(--background);
    position: relative; /* Ensure content respects the fixed hamburger */
}

@media (max-width: 768px) {
    .admin-content {
        padding-top: 60px; /* Add padding to avoid overlap with fixed hamburger */
    }
}

@media (min-width: 769px) {
    .admin-content {
        margin-left: 250px; /* Adjusted back to 250px to match sidebar width */
    }
}