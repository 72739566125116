/* src/styles/AdminGift.css */
.admin-gift {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
}

.admin-gift h2 {
    font-family: "Dancing Script", cursive;
    font-size: 32px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
    margin-bottom: 20px;
}

.mode-toggle {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.toggle-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-btn.active {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
}

.toggle-btn:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.gift-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 400px;
    margin: 0 auto 30px;
}

.gift-form input,
.gift-form select {
    padding: 10px;
    border: 2px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 5px;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.currency-select {
    position: relative;
    display: flex;
    align-items: center;
}

.currency-select select {
    flex: 1;
    padding-right: 30px; /* Space for icon */
}

.currency-icon {
    position: absolute;
    right: 10px;
    color: var(--primary); /* Velvet Plum: #6B456A */
}

.gift-form button {
    padding: 10px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.gift-form button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.gift-list {
    max-width: 600px;
    margin: 0 auto;
}

.gift-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    border-radius: 8px;
    margin-bottom: 10px;
    color: var(--background); /* Warm Ivory: #FDF8F5 - Better contrast with Soft Petal Pink */
}

.gift-info p {
    margin: 5px 0;
}

.gift-actions {
    display: flex;
    gap: 10px;
}

.gift-actions button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--background); /* Warm Ivory: #FDF8F5 - Matches gift-item text color */
}

.gift-actions button:hover {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal h3 {
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 10px;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.modal-cancel, .modal-confirm {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-cancel {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.modal-cancel:hover {
    background-color: #5a3a59; /* Slightly darker Velvet Plum */
}

.modal-confirm {
    background-color: #ff4444;
    color: #fff;
}

.modal-confirm:hover {
    background-color: #cc3333;
}