.developer-credit-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 20px;
    border-radius: 12px;
    margin: 30px auto;
    max-width: 300px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.developer-credit-widget:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.credit-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    gap: 6px;
}

.heart-icon {
    color: #cf4c4c; /* Velvet Plum */
    fill: #cf4c4c; /* Ensure the heart is filled */
    transition: transform 0.3s ease;
}

.credit-text:hover .heart-icon {
    transform: scale(1.3);
}

.social-links {
    display: flex;
    gap: 20px;
}

.social-link {
    color: var(--text); /* Midnight Slate: #2F2A3D */
    transition: color 0.3s ease;
}

.social-link:hover {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.social-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.social-link:hover .social-icon {
    transform: scale(1.2);
}

/* Instagram Icon with Gradient */
.instagram-icon {
    stroke: url(#instagram-gradient); /* Apply gradient to stroke */
    fill: none; /* Ensure the fill is none to show the gradient stroke */
}

/* X Icon Styling */
.x-icon {
    fill: #000000; /* Official X black color */
    transition: fill 0.3s ease, transform 0.3s ease;
}

.social-link:hover .x-icon {
    fill: #1DA1F2; /* Optional: Use Twitter's legacy blue on hover for a nostalgic touch */
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .developer-credit-widget {
        padding: 15px;
        max-width: 250px;
        margin: 20px auto;
    }

    .credit-text {
        font-size: 14px;
    }

    .heart-icon {
        width: 14px;
        height: 14px;
    }

    .social-links {
        gap: 15px;
    }

    .social-icon {
        width: 20px;
        height: 20px;
    }
}

/* Gradient for Instagram Icon */
svg defs {
    display: block;
}

.instagram-gradient {
    display: block;
    width: 0;
    height: 0;
}