.faq-page {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-header {
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 20px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.faq-header h2 {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: var(--text); /* Velvet Plum: #6B456A */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.faq-list-section {
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
    color: var(--accent);
    background-color: var(--accent);
}

.faq-info{
    background-color: var(--background);
    color: var(--accent);
}

.faq-actions{
    background-color: var(--background);
    color: var(--accent);
}



.loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.spinner {
    width: 30px;
    height: 30px;
    border: 4px solid var(--primary); /* Velvet Plum: #6B456A */
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #e74c3c;
    text-align: center;
}

.no-faqs {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--accent);
    background-color: var(--accent);
    
}

.faq-pair {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.faq-question {
    width: 80%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    border-top-left-radius: 0; /* Restore original side-panning design */
    cursor: pointer;
    transition: background-color 0.3s;
}

.faq-question:hover {
    background-color: #d9a3b3; /* Slightly darker Petal Pink on hover */
}

.faq-answer {
    width: 80%;
    padding: 15px;
    background-color: var(--background); /* Soft Petal Pink: #E8B5C4 */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    align-self: flex-end;
    border-top-right-radius: 0; /* Restore original side-panning design */
}

.faq-content {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.faq-icon {
    color: var(--text); /* Velvet Plum: #6B456A */
}

.faq-toggle {
    color: var(--text); /* Velvet Plum: #6B456A */
}

.faq-text {
    flex: 1;
}

.faq-text h3 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0;
}

.faq-text p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    line-height: 1.6;
    margin: 0;
}

.section-icon {
    color: var(--primary); /* Velvet Plum: #6B456A */
}

.contact-section {
    text-align: center;
    margin-top: 30px;
}

.contact-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 15px;
}

.contact-button {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.contact-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    transform: translateY(-2px);
}

.contact-button:active {
    transform: translateY(0);
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .faq-header h2 {
        font-size: 28px;
    }

    .no-faqs,
    .error-message,
    .contact-text {
        font-size: 14px;
    }

    .faq-question,
    .faq-answer {
        width: 90%;
    }

    .faq-text h3 {
        font-size: 16px;
    }

    .faq-text p {
        font-size: 14px;
    }

    .back-button {
        font-size: 14px;
        top: 5px;
        left: 5px;
    }

    .contact-button {
        padding: 8px 15px;
        font-size: 14px;
    }
}