.admin-dashboard {
    text-align: center;
    padding: 20px;
    max-width: 1000px;
    margin: auto;
}

h1 {
    color: #004D40; /* Brand primary color */
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.dashboard-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s;
}

.dashboard-card:hover {
    transform: translateY(-5px);
}

.icon {
    font-size: 30px;
    color: #00A36C; /* Accent color */
    margin-bottom: 10px;
}
