.location-details {
    text-align: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(107, 69, 106, 0.2); /* Velvet Plum border */
}

.location-details h3 {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.location-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.info-container {
    background: #f9f5f8; /* Subtle background to differentiate */
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(107, 69, 106, 0.1);
}

.location-address,
.location-venue {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0; /* Remove default margin since container handles spacing */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.location-venue strong {
    font-weight: 600;
}

.no-data {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text);
    text-align: center;
}

.map-container {
    margin-top: 20px;
    background: #fff;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(107, 69, 106, 0.2);
}

.map-container iframe {
    max-width: 100%;
    border-radius: 8px;
}

.map-link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border-radius: 8px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.2s;
}

.map-link:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    transform: translateY(-2px);
}

.map-link:active {
    transform: translateY(0);
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .location-details h3 {
        font-size: 32px;
    }

    .location-address,
    .location-venue {
        font-size: 18px;
    }

    .info-container {
        padding: 20px;
    }

    .map-container iframe {
        height: 400px; /* Larger map on desktop */
    }

    .map-link {
        padding: 12px 25px;
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .location-details {
        padding: 15px;
    }

    .location-address,
    .location-venue {
        font-size: 14px;
    }

    .info-container {
        padding: 10px;
    }

    .map-container {
        padding: 10px;
    }

    .map-container iframe {
        height: 200px; /* Smaller map on mobile */
    }

    .map-link {
        padding: 8px 15px;
        font-size: 14px;
    }
}