/* src/styles/Theme.css */
.theme-settings {
    padding: 20px;
    background-color: var(--background);
    min-height: 100vh;
}

.theme-settings h1 {
    font-family: "Dancing Script", cursive;
    font-size: 32px;
    color: var(--text);
    text-align: center;
    margin-bottom: 20px;
}

.current-theme {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text);
    font-size: 18px;
}

.mode-toggle {
    display: flex;
    justify-content: center;
    gap: 0;
    margin-bottom: 20px;
}

.toggle-btn {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: var(--secondary);
    color: var(--primary);
    border: none;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-btn:last-child {
    border-radius: 0 5px 5px 0;
}

.toggle-btn.active {
    background-color: var(--primary);
    color: var(--background);
}

.toggle-btn:hover {
    background-color: var(--accent);
}

.tab-content {
    text-align: center;
}

.theme-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    justify-items: center;
    max-width: 600px;
    margin: 0 auto;
}

.theme-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.theme-icon-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 10px;
    background-color: var(--secondary);
    border: 2px solid var(--accent);
    border-radius: 8px;
    cursor: pointer;
    color: var(--text);
    font-size: 14px;
    text-align: center;
    transition: background-color 0.3s, border-color 0.3s;
    width: 100%;
}

.theme-icon-button.selected {
    background-color: var(--accent);
    color: var(--background);
    border-color: var(--primary);
}

.theme-icon-button svg {
    color: var(--primary);
}

.theme-actions {
    display: flex;
    gap: 5px;
    margin-top: 5px;
}

.action-btn {
    padding: 5px;
    background-color: var(--secondary);
    border: 1px solid var(--accent);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.action-btn:hover {
    background-color: var(--accent);
}

.edit-btn svg {
    color: var(--primary);
}

.delete-btn svg {
    color: #ff4444;
}

.theme-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto 30px;
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

@media (min-width: 768px) {
    .form-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .form-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.form-group {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: var(--secondary);
    padding: 15px;
    border: 2px solid var(--accent);
    border-radius: 8px;
}

.form-group label {
    color: var(--text);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    width: 120px;
    text-align: right;
}

.form-group input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 2px solid var(--accent);
    border-radius: 5px;
    font-size: 16px;
    color: var(--text);
    font-family: "Roboto", sans-serif;
    background-color: var(--background);
}

.save-theme-button, .cancel-button {
    padding: 10px;
    background-color: var(--text);
    color: var(--primary);
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    max-width: 200px;
    margin: 10px auto 0;
    display: block;
}

.cancel-button {
    background-color: #ff4444;
    color: #fff;
}

.save-theme-button:hover {
    background-color: var(--accent);
}

.cancel-button:hover {
    background-color: #cc0000;
}