.upload-widget-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.upload-widget {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--text); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.upload-widget:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    transform: scale(1.03);
}

.upload-icon {
    color: var(--background);
}

.upload-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.upload-modal {
    background-color: var(--background);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.upload-modal h3 {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--primary);
    margin-bottom: 10px;
}

.upload-modal p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text);
    margin-bottom: 20px;
}

.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    color: var(--text);
}

.loader-icon {
    color: var(--accent);
    animation: spin 1s linear infinite;
}

.redirect-action {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.manual-redirect-button {
    display: inline-block;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.manual-redirect-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 400px) {
    .upload-widget {
        font-size: 14px;
        padding: 10px 15px;
    }

    .upload-modal h3 {
        font-size: 20px;
    }

    .upload-modal p {
        font-size: 14px;
    }

    .countdown {
        font-size: 14px;
    }

    .manual-redirect-button {
        font-size: 14px;
        padding: 8px 16px;
    }

    
}