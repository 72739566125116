.our-story-container {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.story-header {
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 20px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.story-header h2 {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.section-icon {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

/* Header Picture and Description */
.story-header-content {
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.story-header-image {
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer;
}

.story-header-description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
    margin: 0;
    line-height: 1.4;
}

/* Timeline Container */
.story-timeline {
    padding: 20px 0;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    flex: 1;
    box-sizing: border-box;
}

/* Story Event */
.story-event {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    width: 100%;
}

/* Story Content */
.story-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: 2px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 100%;
}

/* Alternate Sides for Visual Connection */
.story-event:nth-child(odd) .story-content {
    transform: translateX(-5%);
}

.story-event:nth-child(even) .story-content {
    transform: translateX(5%);
}

/* Image */
.story-image {
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer;
}

/* Text */
.story-text {
    text-align: center;
    width: 100%;
    overflow-wrap: break-word;
}

.story-title {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
    color: var(--text); /* Velvet Plum: #6B456A */
    margin: 0 0 10px;
}

.story-description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0;
    line-height: 1.4;
}

.no-events {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
    margin-top: 20px;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: var(--background); /* Warm Ivory: #FDF8F5 */
    border-radius: 8px;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
}

.modal-image {
    width: 100%;
    max-height: 60vh;
    object-fit: contain;
    border-radius: 6px;
    margin-bottom: 15px;
}

.modal-description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0;
    line-height: 1.4;
    overflow-wrap: break-word;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .our-story-container {
        padding: 0; /* Remove all padding */
        width: 100%;
        box-sizing: border-box;
    }

    .story-header {
        max-width: 100%;
        padding: 10px;
        margin-bottom: 10px;
    }

    .story-header h2 {
        font-size: 28px;
    }

    .no-events {
        font-size: 14px;
    }

    .back-button {
        font-size: 14px;
        top: 5px;
        left: 5px;
    }

    .story-timeline {
        padding: 0;
        max-width: 100%;
        width: 100%;
    }

    .story-event {
        margin: 10px 0;
        width: 100%;
    }

    .story-content {
        padding: 10px;
        width: 100%;
        transform: none !important; /* Remove translateX entirely */
        border-radius: 0; /* Remove border radius for edge-to-edge */
        border-left: none; /* Remove side borders */
        border-right: none;
    }

    .story-header-content {
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px;
    }

    .story-header-image,
    .story-image {
        width: 100%;
        border-radius: 0; /* Remove border radius for edge-to-edge */
    }

    .story-title {
        font-size: 16px;
    }

    .story-description {
        font-size: 12px;
    }

    .story-header-description {
        font-size: 14px;
    }

    /* Modal Adjustments for Small Screens */
    .modal-content {
        padding: 0;
        max-width: 100%;
        width: 100%;
        border-radius: 0;
        margin: 0;
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal-image {
        max-height: 50vh;
        margin-bottom: 10px;
        width: 100%;
    }

    .modal-description {
        font-size: 12px;
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;
    }

    .close-button {
        top: 5px;
        right: 5px;
    }
}

@media (min-width: 768px) {
    .story-content {
        width: calc(70% - 40px);
    }

    .story-event:nth-child(odd) .story-content {
        transform: translateX(0%);
    }

    .story-event:nth-child(even) .story-content {
        transform: translateX(0%);
    }

    .story-title {
        font-size: 20px;
    }

    .story-description,
    .modal-description {
        font-size: 16px;
    }

    .story-header-description {
        font-size: 18px;
    }
}