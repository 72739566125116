.admin-rsvp {
    padding: 20px;
}

.admin-rsvp h3 {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 20px;
    text-align: center;
}

.no-data {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
}

.rsvp-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.rsvp-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.rsvp-info h4 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 5px;
}

.rsvp-info p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 5px 0;
}

.rsvp-actions {
    display: flex;
    gap: 10px;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    color: #e74c3c;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: #e74c3c;
    color: #fff;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .admin-rsvp h3 {
        font-size: 28px;
    }

    .rsvp-info h4 {
        font-size: 20px;
    }

    .rsvp-info p {
        font-size: 16px;
    }
}