/* CountDown.css */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Roboto:wght@400&display=swap');

.countdown-container {
    width: 100%;
    max-width: 400px;
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--text); /* Midnight Slate: #2F2A3D */
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.countdown-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 10px;
}

.countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.countdown-icon {
    font-size: 24px;
    color: var(--icon); /* Velvet Plum: #6B456A */
    margin-bottom: 5px;
}

.countdown-value {
    font-family: 'Dancing Script', cursive;
    font-size: 28px;
    font-weight: 700;
    color: var(--text); /* Warm Ivory: #FDF8F5 */
}

.countdown-label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.countdown-complete {
    font-family: 'Dancing Script', cursive;
    font-size: 24px;
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    margin: 0;
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .countdown-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
        gap: 15px;
    }

    .countdown-icon {
        font-size: 20px;
    }

    .countdown-value {
        font-size: 24px;
    }

    .countdown-label {
        font-size: 12px;
    }

    .countdown-complete {
        font-size: 20px;
    }
}

@media (min-width: 768px) {
    .countdown-container {
        max-width: 600px;
    }

    .countdown-value {
        font-size: 32px;
    }

    .countdown-label {
        font-size: 16px;
    }
}