.our-day-page {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.our-day-header {
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 20px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.our-day-header h2 {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.section-icon {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.media-switch {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    width: 100%;
    max-width: 600px;
}

.switch-tab {
    padding: 10px 20px;
    background-color: var(--text); /* Midnight Slate: #2F2A3D */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.switch-tab.active {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
}

.switch-tab:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.our-day-section {
    width: 100%;
    max-width: 600px;
}

.tab-content {
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .our-day-header h2 {
        font-size: 40px;
    }
}