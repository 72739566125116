/* src/styles/AdminFaq.css */
.admin-faq {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
}

.admin-faq h2 {
    font-family: "Dancing Script", cursive;
    font-size: 32px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
    margin-bottom: 20px;
}

.mode-toggle {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.toggle-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: #E8B5C4; /* Velvet Plum: #6B456A */
    color: #6B456A; 
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-btn.active {
    background-color: #6B456A; 
}

.toggle-btn:hover {
    background-color: #E8B5C4; 
}

.faq-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 400px;
    margin: 0 auto 30px;
}

.faq-form input,
.faq-form textarea {
    padding: 10px;
    border: 2px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 5px;
    font-size: 16px;
    color: var(--text);
    font-family: "Roboto", sans-serif;
}

.faq-form textarea {
    resize: vertical;
}

.faq-form button {
    padding: 10px;
    background-color: var(--text); /* Velvet Plum: #6B456A */
    color: var(--primary); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.faq-form button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.faq-list {
    max-width: 600px;
    margin: 0 auto;
}

.faq-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    border-radius: 8px;
    margin-bottom: 10px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.faq-info p {
    margin: 5px 0;
}

.faq-actions {
    display: flex;
    gap: 10px;
}

.faq-actions button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.faq-actions button:hover {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal h3 {
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 10px;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.modal-cancel, .modal-confirm {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-cancel {
    background-color: var(--secondary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.modal-cancel:hover {
    background-color: #5a3a59;
}

.modal-confirm {
    background-color: #ff4444;
    color: #fff;
}

.modal-confirm:hover {
    background-color: #cc3333;
}