.pinata-uploader-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
}

.queue-status h3 {
    margin-bottom: 10px;
    color: var(--primary);
    font-size: 1.2rem;
}

.queue-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 8px;
}

.queue-item p {
    margin: 0;
    font-size: 0.9rem;
}

.progress-bar {
    width: 100%;
    height: 15px;
    background: #f5f5f5;
    border-radius: 5px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background: var(--primary);
    transition: width 0.3s ease;
}

.queue-item a {
    color: var(--primary);
    text-decoration: none;
    font-size: 0.8rem;
}

.queue-item a:hover {
    text-decoration: underline;
}