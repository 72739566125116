.admin-photos-upload {
    width: 100%;
}

.admin-photos-upload h3 {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 20px;
    text-align: center;
}

.upload-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Side-by-side forms */
    gap: 20px;
    width: 100%;
}

.upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.admin-photos-upload button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: auto; /* Push button to bottom of form */
}

.admin-photos-upload button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.file-details {
    background-color: rgba(232, 181, 196, 0.1); /* Light pink tint */
    padding: 15px;
    border-radius: 8px;
    flex-grow: 1; /* Allow it to expand */
}

.file-input-group {
    margin-bottom: 20px;
}

.file-input-group p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0 0 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .upload-grid {
        grid-template-columns: 1fr; /* Stack forms vertically */
    }
}