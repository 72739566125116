.admin-location {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.admin-location h3 {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 25px;
    text-align: center;
}

/* Mode Switch */
.mode-switch {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
    flex-direction: row; /* Default horizontal for larger screens */
}

.mode-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #f0f0f0;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    border: none;
    border-radius: 25px;
    padding: 10px 20px; /* Default padding for larger screens */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.2s;
    white-space: nowrap;
    flex-shrink: 1;
    min-width: 120px;
}

.mode-button.active {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.mode-button:hover:not(.active) {
    background-color: #e0e0e0;
    transform: translateY(-2px);
}

.mode-button:active {
    transform: translateY(0);
}

/* Location Form */
.location-form {
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 8px;
    display: block;
}

.form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(107, 69, 106, 0.2);
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text);
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
    border-color: var(--primary);
    box-shadow: 0 0 6px rgba(107, 69, 106, 0.3);
}

.form-group input::placeholder {
    color: var(--primary);
    opacity: 0.7;
}

.form-error {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #e74c3c;
    margin-bottom: 15px;
    text-align: center;
}

.submit-button {
    width: 100%;
    background-color: var(--primary);
    color: var(--background);
    border: none;
    border-radius: 8px;
    padding: 12px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    transform: translateY(-2px);
}

.submit-button:active {
    transform: translateY(0);
}

/* Location List */
.location-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.location-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.location-info h4 {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: var(--primary);
    margin-bottom: 5px;
}

.location-info p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text);
    margin: 5px 0;
}

.location-info a {
    color: var(--primary);
    text-decoration: none;
}

.location-info a:hover {
    color: var(--accent);
    text-decoration: underline;
}

.location-actions {
    display: flex;
    gap: 10px;
}

.edit-button,
.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.edit-button {
    color: var(--primary);
}

.delete-button {
    color: #e74c3c;
}

.edit-button:hover {
    background-color: var(--accent);
    color: var(--background);
}

.delete-button:hover {
    background-color: #e74c3c;
    color: #fff;
}

.no-data {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text);
    text-align: center;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .admin-location h3 {
        font-size: 32px;
    }

    .mode-button {
        padding: 12px 25px;
        font-size: 18px;
    }

    .form-group label,
    .form-group input,
    .submit-button {
        font-size: 18px;
    }

    .location-info h4 {
        font-size: 20px;
    }

    .location-info p {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .mode-switch {
        flex-direction: column; /* Stack vertically on small screens */
        gap: 10px;
        align-items: center;
    }

    .mode-button {
        padding: 8px 12px; /* Reduced padding for mobile */
        font-size: 14px;
        min-width: 0;
        width: 100%;
        max-width: 200px;
    }

    .location-form {
        padding: 15px;
    }

    .location-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .location-actions {
        align-self: flex-end;
    }
}