.home-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    overflow-y: auto;
}

/* Main Content */
.home-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: var(--background);
    gap: 10px;
}

/* Image Container */
.image-container {
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1 / 1; /* Ensure consistent aspect ratio */
    background-color: var(--background);
    border: 2px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 8px;
    padding: 5px;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}

.image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure full image visibility */
    border-radius: 6px;
}

.image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

/* Countdown Container */
.countdown-container {
    width: 100%;
    max-width: 400px;
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    flex-shrink: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Fixed Bottom Buttons Container */
.button-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: var(--background);
    padding: 8px 0;
    border-top: 2px solid var(--secondary); /* Champagne Gold: #CDA875 */
    z-index: 10;
    flex-shrink: 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.button-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    padding: 0 8px;
    max-width: 400px;
    margin: 0 auto;
}

.button-wrapper {
    display: flex;
    justify-content: center;
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
    .image-container {
        max-width: 600px;
        aspect-ratio: 1 / 1;
    }

    .countdown-container {
        max-width: 600px;
    }

    .button-grid {
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;
        padding: 0 15px;
        max-width: 600px;
    }
}