.admin-photos-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.admin-photos-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary); /* Matches AdminImages.css */
    font-family: "Dancing Script", cursive; /* Retain original font */
    font-size: 36px; /* Matches original h2 size */
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background: #f5f5f5; /* Matches AdminImages.css */
    border: none;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    font-size: 1rem;
    color: #1a1a1a; /* Matches AdminImages.css */
    transition: background 0.3s ease;
}

.tab.active {
    background: var(--primary); /* Matches AdminImages.css */
    color: white;
}

.tab-content {
    border: 1px solid #ddd; /* Matches AdminImages.css */
    border-radius: 0 5px 5px 5px;
    padding: 20px;
    background: white; /* Matches AdminImages.css */
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .admin-photos-container h1 {
        font-size: 28px;
    }

    .tab {
        font-size: 14px;
        padding: 8px 16px;
    }
}