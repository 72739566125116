

.admin-login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: var(--background);
}

.login-box {
    background: var(--input-bg);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 350px;
}

h2 {
    color: var(--primary);
    margin-bottom: 1rem;
}

.input-group {
    display: flex;
    align-items: center;
    background: var(--input-bg);
    border: 1px solid var(--input-border);
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.input-group .icon {
    color: var(--primary);
    margin-right: 0.5rem;
}

input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0.5rem;
    font-size: 1rem;
}

/* 🔥 Button Styles */
button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background: var(--primary);
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    transition: background 0.3s ease;
}

/* Ensure icon is always visible */
button .icon {
    color: white;
}

/* Button hover effect */
button:hover {
    background: var(--button-hover);
}

/* Disabled button */
button:disabled {
    background: var(--input-border);
    cursor: not-allowed;
}

/* 🔄 Loader Animation */
.loading-icon.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
