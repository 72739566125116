.admin-images-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.admin-images-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary);
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background: #f5f5f5;
    border: none;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    font-size: 1rem;
    color: #1a1a1a;
    transition: background 0.3s ease;
}

.tab.active {
    background: var(--primary);
    color: white;
}

.tab-content {
    border: 1px solid #ddd;
    border-radius: 0 5px 5px 5px;
    padding: 20px;
    background: white;
}