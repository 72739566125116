/* src/styles/HomeButtonIcon.css */
.home-button-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background: var(--background);
    border-radius: 12px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: var(--text);
    font-size: 14px;
    transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
    border: 1px solid var(--accent);
}

.home-button-icon:hover {
    transform: scale(1.1);
    background: var(--secondary);
}

.home-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 6px;
    color: var(--text); /* Explicitly set to Midnight Slate: #2F2A3D */
}