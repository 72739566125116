

.sidebar {
    width: 250px;
    height: 100vh;
    background: var(--sidebar-bg);
    color: var(--text);
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    z-index: 1000;
}

.menu-toggle {
    display: none; /* Hidden by default */
    border: none; /* Remove any border */
    padding: 10px;
    position: fixed; /* Fixed so it stays in place */
    top: 10px;
    left: 10px; /* Keep it on the left */
    z-index: 1001; /* Above the sidebar */
    margin: 0; /* Remove any default margins */
    cursor: pointer;
    background: none; /* Explicitly no background */
    color: var(--primary);
}

/* Remove hover effect */
.menu-toggle:hover {
    background: none; /* Ensure no background on hover */
    
}

.logo {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary);
    padding: 1rem;
    border-bottom: 2px solid var(--primary);
}

.nav-links {
    list-style: none;
    padding: 1rem;
    margin: 0;
}

.nav-links li {
    margin: 10px 0;
}

.nav-links a {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--primary);
    text-decoration: none;
    font-size: 1rem;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.nav-links a:hover {
    background: var(--primary);
    color: white;
}

.nav-links svg {
    margin-right: 10px;
}

.logout {
    margin-top: auto;
    padding: 10px;
    background: var(--primary);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 2px solid var(--primary);
    transition: background 0.3s ease;
}

.logout:hover {
    background: var(--sidebar-hover);
}

@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .menu-toggle {
        display: block; /* Show on mobile */
        left: 10px; /* Explicitly keep it on the left */
        right: auto; /* Prevent any right alignment */
    }
}

@media (min-width: 769px) {
    .sidebar {
        transform: translateX(0);
    }

    .menu-toggle {
        display: none; /* Hide on desktop */
    }
}