.onboarding-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    overflow: hidden;
}

.onboarding-slide {
    max-width: 80%;
}

h1 {
    font-size: 28px;
    font-family: "Dancing Script", cursive;
    color: var(--primary); /* Velvet Plum: #6B456A */
}

.onboarding-lottie {
    width: 300px;
    height: 300px;
    margin: 20px 0;
}

p {
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.onboarding-bottom {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: bold;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.swipe-icon {
    width: 24px;
    height: 24px;
    animation: bounce 1.5s infinite alternate;
}

.explore-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--background); /* Soft Petal Pink: #E8B5C4 */
    color: var(--text); /* Midnight Slate: #2F2A3D */
    border: none;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.explore-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.love-icon {
    font-size: 24px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

/* When animating */
.love-icon.animate {
    transform: translateY(-50px) scale(1.5);
    opacity: 0;
}

@keyframes bounce {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(5px);
    }
}