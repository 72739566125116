.admin-photos-manage {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling at container level */
}

.admin-photos-manage h3 {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin-bottom: 20px;
    text-align: center;
}

.photos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusted to auto-fit */
    gap: 10px; /* Smaller gap to fit */
    width: 100%;
    max-width: 100%;
    padding: 0; /* Remove any padding that could cause overflow */
    box-sizing: border-box;
}

.photo-item {
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    padding: 8px; /* Minimal padding */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure item fits its grid cell */
    box-sizing: border-box; /* Include padding in width */
}

.media-preview {
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 8px;
}

.photo-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.photo-info h4 {
    font-family: "Dancing Script", cursive;
    font-size: 16px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin: 0 0 5px;
}

.photo-info p {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0 0 8px;
}

.action-buttons {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    margin-top: auto;
}

.action-buttons button {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--text); /* Midnight Slate: #2F2A3D */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.action-buttons button:first-child {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
}

.action-buttons button:last-child {
    background-color: #e63946; /* Red for delete */
}

.action-buttons button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    padding: 15px;
    border-radius: 8px;
    width: 90%;
    max-width: 350px;
    text-align: center;
}

.modal h3 {
    font-family: "Dancing Script", cursive;
    font-size: 20px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin: 0 0 10px;
}

.modal p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0 0 15px;
}

.modal-buttons {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.modal-cancel {
    background-color: var(--text); /* Midnight Slate: #2F2A3D */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal-cancel:hover {
    background-color: #4a3f5e; /* Lighter slate */
}

.modal-confirm {
    background-color: #e63946; /* Red */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal-confirm:hover {
    background-color: #d00000; /* Darker red */
}

.sort-controls {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sort-controls label {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.sort-controls select {
    padding: 6px;
    border: 1px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: var(--text);
    background-color: var(--background);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .photos-list {
        grid-template-columns: repeat(2, 1fr); /* Exactly 2 items, no min width */
        gap: 8px; /* Even smaller gap */
    }

    .photo-item {
        padding: 6px;
    }

    .media-preview {
        max-height: 100px; /* Smaller for mobile */
    }

    .photo-info h4 {
        font-size: 14px;
    }

    .photo-info p {
        font-size: 10px;
    }

    .action-buttons button {
        padding: 5px 8px;
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    .photos-list {
        grid-template-columns: 1fr; /* Stack on very small screens */
    }
}