/* src/styles/AdminOurStory.css */
.admin-our-story {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
}

.admin-our-story h2 {
    font-family: "Dancing Script", cursive;
    font-size: 32px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
    margin-bottom: 20px;
}

.mode-toggle {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.toggle-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-btn.active {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
}

.toggle-btn:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.event-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 400px;
    margin: 0 auto 30px;
}

.event-form input,
.event-form textarea,
.event-form select {
    padding: 10px;
    border: 2px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 5px;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-family: "Roboto", sans-serif;
}

.event-form textarea {
    resize: vertical;
}

.event-form button {
    padding: 10px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.event-form button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.event-list {
    max-width: 600px;
    margin: 0 auto;
    color: var(--accent);
}

.event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--accent); 
    border-radius: 8px;
    margin-bottom: 10px;
    color: var(--background); 
}

.event-info p {
    margin: 5px 0;
}

.event-info a {
    color: var(--primary); /* Velvet Plum: #6B456A - Distinguishable link color */
    text-decoration: underline;
}

.event-info a:hover {
    color: var(--accent); /* Champagne Gold: #CDA875 - Hover effect for links */
}

.event-actions {
    display: flex;
    gap: 10px;
}

.event-actions button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text); /* Warm Ivory: #FDF8F5 - Matches event-item text color */
}

.event-actions button:hover {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
}

.modal h3 {
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 10px;
}

.modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
}

.modal-cancel,
.modal-confirm {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-cancel {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.modal-cancel:hover {
    background-color: #5a3a59; /* Slightly darker Velvet Plum */
}

.modal-confirm {
    background-color: #ff4444;
    color: #fff;
}

.modal-confirm:hover {
    background-color: #cc3333;
}

/* Header Management Section */
.header-management {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-management h3 {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 15px;
}

.header-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    resize: vertical;
}

.header-form .image-upload {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header-form .image-upload label {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    background-color: var(--secondary); /* Soft Petal Pink: #E8B5C4 */
    border-radius: 5px;
    cursor: pointer;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-family: "Roboto", sans-serif;
    font-size: 14px;
}

.header-form .image-upload label:hover {
    background-color: #d9a3b3; /* Slightly darker Soft Petal Pink */
}

.header-form .uploaded-image-preview {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.header-form .uploaded-image-preview a {
    color: var(--primary); /* Velvet Plum: #6B456A */
    text-decoration: underline;
}

.header-form button {
    padding: 10px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.header-form button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}