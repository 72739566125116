.event-wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* Dress Code Section (Fixed at Top) */
.dress-code-section {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--background);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(107, 69, 106, 0.2); /* Velvet Plum border */
    margin-bottom: 20px;
}

.dress-code-container {
    text-align: center;
}

.dress-code-container h3 {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
    color: var(--text); /* Velvet Plum: #6B456A */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.dress-code-container p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0;
}

/* Event Details Section */
.event-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.event-container {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(107, 69, 106, 0.2);
    padding: 20px;
    text-align: center;
}

.event-container h3 {
    font-family: "Dancing Script", cursive;
    font-size: 28px;
    color: var(--text); /* Velvet Plum: #6B456A */
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.event-description {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 15px;
}

.event-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.event-date,
.event-time {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0;
}

.event-date strong,
.event-time strong {
    font-weight: 600;
}

.no-data {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .event-wrapper {
        padding: 30px;
    }

    .dress-code-container h3,
    .event-container h3 {
        font-size: 32px;
    }

    .dress-code-container p,
    .event-description,
    .event-date,
    .event-time {
        font-size: 18px;
    }

    .dress-code-section,
    .event-container {
        padding: 25px;
    }
}

@media (max-width: 500px) {
    .event-wrapper {
        padding: 15px;
    }

    .dress-code-container h3,
    .event-container h3 {
        font-size: 24px;
    }

    .dress-code-container p,
    .event-description,
    .event-date,
    .event-time {
        font-size: 14px;
    }

    .dress-code-section,
    .event-container {
        padding: 15px;
    }
}