.photos-page {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.photos-header {
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 20px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.photos-header h2 {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.section-icon {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.media-switch {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
    width: 100%;
    max-width: 600px;
}

.switch-tab {
    padding: 10px 20px;
    background-color: var(--text); /* Midnight Slate: #2F2A3D */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.switch-tab.active {
    background-color: var(--primary); /* Velvet Plum: #6B456A */
}

.switch-tab:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.photos-section {
    width: 100%;
    max-width: 600px;
}

.no-photos {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
}

.photos-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.photo-card {
    cursor: pointer;
}

.media-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio for videos */
}

.media-content {
    width: 100%;
    margin-bottom: 10px;
}

.media-image {
    max-height: 300px;
    object-fit: cover;
}

.media-video {
    width: 100%; /* Ensure the video takes the full width of its container */
    max-height: 300px;
    object-fit: contain; /* Keep content visible without cropping */
    background: #000; /* Black background for empty space */
    display: block; /* Ensure the video element is a block element */
}

/* Ensure video controls are accessible */
.media-video::-webkit-media-controls {
    z-index: 1; /* Ensure controls are above other elements */
}

/* Ensure the watermark doesn't overlap video controls */
.watermark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.5);
    padding: 2px 6px;
    border-radius: 3px;
    z-index: 0; /* Ensure watermark is below video controls */
}

.photo-details h3 {
    font-family: "Dancing Script", cursive;
    font-size: 20px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin: 5px 0;
}

.photo-details p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0 0 5px;
}

.photo-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.photo-modal {
    position: relative;
    width: 90%;
    max-width: 800px;
    text-align: center;
}

.modal-image-wrapper {
    position: relative;
    width: 100%;
}

.modal-image {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
}

.modal-watermark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.5);
    padding: 3px 8px;
    border-radius: 3px;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .photos-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .photos-header h2 {
        font-size: 40px;
    }

    .photo-details h3 {
        font-size: 22px;
    }

    .photo-details p {
        font-size: 16px;
    }

    .watermark {
        font-size: 14px;
    }

    .modal-watermark {
        font-size: 16px;
    }

    .media-video {
        max-height: 300px; /* Keep the same height for larger screens */
    }
}

/* Adjustments for smaller screens */
@media (max-width: 767px) {
    .photos-grid {
        grid-template-columns: 1fr; /* Ensure single column on smaller screens */
    }

    .media-wrapper {
        aspect-ratio: 16 / 9; /* Maintain aspect ratio for videos */
    }

    .media-video {
        max-height: 300px; /* Reduce height on smaller screens for better fit */
        object-fit: contain; /* Ensure the video isn't cropped */
        width: 100%; /* Full width of the container */
    }

    .media-image {
        max-height: 350px; /* Reduce image height on smaller screens for consistency */
    }

    .watermark {
        font-size: 10px; /* Smaller watermark on smaller screens */
        bottom: 5px; /* Move closer to the edge to avoid overlapping controls */
        right: 5px;
    }

    .photos-header h2 {
        font-size: 28px; /* Smaller header on smaller screens */
    }

    .photo-details h3 {
        font-size: 18px;
    }

    .photo-details p {
        font-size: 12px;
    }
}