/* src/styles/Gift.css */
.gift-page {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gift-header {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 1px solid var(--accent); /* Champagne Gold: #CDA875 */
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.back-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.gift-header h2 {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0;
}

.intro-section {
    width: 100%;
    max-width: 600px;
    margin-bottom: 40px;
    padding: 20px;
    background-color: rgba(232, 181, 196, 0.1); /* Very light pink tint */
    border-radius: 8px;
}

.intro-message {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    line-height: 1.6;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    text-align: center;
    margin: 0;
}

.gift-details-section {
    width: 100%;
    max-width: 600px;
    margin-bottom: 40px;
}

.no-details {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
    padding: 20px;
}

.gift-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gift-detail-card {
    padding: 20px;
    background-color: var(--accent); /* Soft Petal Pink: #E8B5C4 */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(205, 168, 117, 0.3); /* Subtle Champagne Gold border */
    transition: transform 0.3s, box-shadow 0.3s;
}

.gift-detail-item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 8px 0;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.detail-icon {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.gift-detail-item p {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.copy-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: var(--primary); /* Velvet Plum: #6B456A */
    transition: color 0.3s;
}

.copy-btn:hover {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.thank-you-section {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px; /* Ensures vertical centering */
}

.thank-you {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    text-align: center;
    margin: 0;
}

.section-icon {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .gift-header h2 {
        font-size: 28px;
    }

    .intro-message,
    .no-details {
        font-size: 14px;
    }

    .gift-detail-item p {
        font-size: 14px;
    }

    .thank-you {
        font-size: 20px;
    }

    .back-button {
        font-size: 14px;
        padding: 4px 8px;
    }

    .gift-detail-card {
        padding: 15px;
    }
}

@media (min-width: 768px) {
    .gift-header h2 {
        font-size: 40px;
    }

    .intro-message {
        font-size: 18px;
    }

    .gift-detail-item p {
        font-size: 18px;
    }

    .thank-you {
        font-size: 28px;
    }
}