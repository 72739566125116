.upload-images h2 {
    margin-bottom: 20px;
    color: var(--primary);
}

.upload-images form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-group label {
    font-weight: bold;
    color: #1a1a1a;
}

.form-group input,
.form-group textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.file-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.file-input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.file-input-group p {
    margin: 0;
    font-weight: bold;
}

.upload-images button {
    padding: 10px;
    background: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.upload-images button:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.error {
    color: red;
    margin: 5px 0;
}

.success {
    color: green;
    margin: 5px 0;
}