.rsvp-details {
    padding: 0;
}

.rsvp-details h3 {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--text); /* Velvet Plum: #6B456A */
    margin-bottom: 10px;
    text-align: center;
}

.no-data {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
}

.rsvp-success {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Velvet Plum: #6B456A */
    text-align: center;
    margin-bottom: 20px;
}

.rsvp-form {
    padding: 20px;
}

.rsvp-form-group {
    margin-bottom: 15px;
    background-color: #fff; /* Explicitly set to white to match the screenshot */
    border: 1px solid rgba(107, 69, 106, 0.2); /* Velvet Plum with opacity */
    border-radius: 10px;
    padding: 10px;
}

.rsvp-form-group label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 5px;
    display: block;
}

.input-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid rgba(107, 69, 106, 0.2); /* Velvet Plum with opacity */
    border-radius: 10px;
    padding: 5px 10px;
}

.input-icon {
    color: var(--text); /* Velvet Plum: #6B456A */
    margin-right: 10px;
}

.rsvp-form-group input,
.rsvp-form-group textarea {
    flex: 1;
    border: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    background: transparent;
}

.rsvp-form-group input::placeholder,
.rsvp-form-group textarea::placeholder {
    color: var(--text); /* Velvet Plum: #6B456A */
    opacity: 0.7;
}

.rsvp-form-group textarea {
    resize: none;
    height: 100px;
}

.rsvp-submit-button {
    width: 100%;
    background-color: var(--text); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    border-radius: 10px;
    padding: 15px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.rsvp-submit-button:disabled {
    background-color: var(--accent);
    cursor: not-allowed;
}

.rsvp-submit-button:hover:not(:disabled) {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.contact-section {
    text-align: center;
    margin-top: 20px;
}

.contact-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin-bottom: 10px;
}

.contact-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.contact-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--accent);
    border-radius: 10px;
    padding: 10px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Velvet Plum: #6B456A */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .rsvp-details h3 {
        font-size: 28px;
    }

    .rsvp-success,
    .rsvp-form-group label,
    .rsvp-form-group input,
    .rsvp-form-group textarea,
    .rsvp-submit-button,
    .contact-text,
    .contact-button {
        font-size: 18px;
    }
}