/* src/styles/Wish.css */
.wish-page {
    padding: 20px;
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wish-header {
    position: relative;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-bottom: 20px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: none;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.wish-header h2 {
    font-family: "Dancing Script", cursive;
    font-size: 36px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.section-icon {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.submit-wish-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s;
}

.submit-wish-btn:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

.wishes-section {
    width: 100%;
    max-width: 600px;
}

.no-wishes {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    text-align: center;
}

.wishes-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.wish-card {
    position: relative;
    padding: 15px;
    background-color: var(--accent); /* Soft Petal Pink: #E8B5C4 */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.wish-icon-wrapper {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    border-radius: 50%;
    padding: 6px;
}

.wish-icon {
    color: var(--background); /* Warm Ivory: #FDF8F5 */
}

.wish-content {
    margin-top: 20px;
    text-align: center;
}

.wish-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0 0 5px;
    font-style: italic;
}

.wish-name {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    margin: 0 0 10px;
}

.like-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.like-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.like-button svg {
    color: var(--text); /* Midnight Slate: #2F2A3D */
    transition: color 0.3s;
}

.like-button.liked svg {
    color: #ff0000; /* Red when liked */
}

.like-button:hover svg {
    color: var(--accent); /* Champagne Gold: #CDA875 */
}

.like-count {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    margin: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: var(--background); /* Warm Ivory: #FDF8F5 */
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-content h3 {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    color: var(--primary); /* Velvet Plum: #6B456A */
    text-align: center;
    margin: 0 0 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--accent); /* Champagne Gold: #CDA875 */
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--text); /* Midnight Slate: #2F2A3D */
}

.submit-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: var(--primary); /* Velvet Plum: #6B456A */
    color: var(--background); /* Warm Ivory: #FDF8F5 */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: var(--accent); /* Champagne Gold: #CDA875 */
}

/* Responsive Adjustments */
@media (max-width: 400px) {
    .wish-header h2 {
        font-size: 28px;
    }

    .submit-wish-btn {
        font-size: 14px;
        padding: 8px 16px;
    }

    .wish-text {
        font-size: 14px;
    }

    .wish-name,
    .like-count {
        font-size: 12px;
    }

    .modal-content {
        padding: 15px;
    }

    .modal-content h3 {
        font-size: 20px;
    }
}

@media (min-width: 768px) {
    .wish-card {
        padding: 20px;
    }

    .wish-text {
        font-size: 18px;
    }

    .wish-name,
    .like-count {
        font-size: 16px;
    }
}